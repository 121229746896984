import { Col, Layout, Progress, Row, Space, Tag, Typography } from 'antd'
import {BsPhone, BsLaptop} from 'react-icons/bs'
import { SiBootstrap, SiCreatereactapp, SiCss3, SiDjango, SiGit, SiGithub, SiGnometerminal, SiHtml5, SiJavascript, SiJunit5, SiLinux, SiMariadb, SiMysql, SiNginx, SiPhp, SiPostgresql, SiPython, SiReact, SiRedux, SiSass, SiSelenium, SiSqlite, SiTypescript, SiWindows, SiWindowsterminal } from "react-icons/si";

import { useContext, useRef, useEffect } from 'react';
import { RoutesContext } from '../../context/RoutesContext';
import { useIsVisible } from '../../assets/hooks/useIsVisible';

export default function About() {
    const {setIndex} = useContext(RoutesContext)
    const ref = useRef()
    const isVisible = useIsVisible(ref);

    useEffect(()=>{
        if(isVisible){
            setIndex(1)
        }
    },[isVisible, setIndex])

  return (
    <Layout className='about' id="acerca" >
        <Row ref={ref}>
            <Col xs={24}>
                <Typography.Title level={1} className='title'>Acerca de mí</Typography.Title>
                <Progress className='bar' strokeColor='#51138a' percent={100}  showInfo={false} size={[50,12]} />
                <Typography.Text className='text'>Aquí encontrarás información sobre mi, las hablidades con las que cuento actualmente y un poco de lo que hago</Typography.Text>
            </Col>
        </Row>
        <Row gutter={[48, 32]}>
            <Col xs={24} lg={12}>
                <Typography.Title level={3} className='subTitle'>Conóceme</Typography.Title>
                <Typography.Paragraph className='text'>Soy un desarrollador FullStack, experimentado principalmente en el desarrollo Frontend de páginas y aplicaciones web desde hace 7 años, adicionalmente tengo 
                    5 años trabajando con tecnologías Backend y colaborando con otros profesionales en la creación de sistemas web.</Typography.Paragraph>
                <Typography.Paragraph className='text'>Recientemente he retomado el desarrollo de Apps móviles multiplataforma haciendo uso de React Native.</Typography.Paragraph>
                <Typography.Paragraph className='text'>Comencé a involucrarme en el mundo del testeo de software, por lo que decidí capacitarme en testing automation, concluyéndolo
                     exitosamente el 29 de Julio de 2023</Typography.Paragraph>
                <Typography.Paragraph className='text'>Me gusta conocer nuevas tecnologías, disfruto del tiempo libre rodeado de naturaleza, me encanta el motociclismo y ciclismo 
                    de aventura</Typography.Paragraph>
                <Typography.Paragraph className='text'>Estoy abierto a tomar proyectos y oportunidades de trabajo donde pueda aportar con mi conocimiento, aprender y crecer 
                    profesionalmente. Si tiene una buena oportunidad que se adapte a mis habilidades y experiencia, no dude en ponerse en contacto conmigo 
                    a través de LinkedIn, correo electrónico o alguna de las redes sociales que aquí comparto.</Typography.Paragraph>
            </Col>
            <Col xs={24} lg={12} >
                <Typography.Title level={3} className='subTitle'>Mis Skils</Typography.Title>
                <Space size={[10, 16]} wrap>
                    <Tag color="geekblue" className='skils' icon={<SiReact className='icons'/>}>
                        React js
                    </Tag>
                    <Tag color="cyan" className='skils' icon={<SiRedux className='icons'/>}>
                        Redux
                    </Tag>
                    <Tag color="blue" className='skils' icon={<SiCreatereactapp className='icons'/>}>
                        React Native
                    </Tag>
                    <Tag color="blue" className='skils' icon={<SiTypescript className='icons'/>}>
                        TypeScript
                    </Tag>
                    <Tag color="gold" className='skils' icon={<SiJavascript className='icons'/>}>
                        JavaScript
                    </Tag>
                    <Tag color="pink" className='skils' icon={<SiSass className='icons'/>}>
                        SASS
                    </Tag>
                    <Tag color="orange" className='skils' icon={<SiHtml5 className='icons'/>}>
                        HTML
                    </Tag>
                    <Tag color="blue" className='skils' icon={<SiCss3 className='icons'/>}>
                        CSS
                    </Tag>
                    <Tag color="purple" className='skils' icon={<SiBootstrap className='icons'/>}>
                        Bootstrap
                    </Tag>
                    <Tag color="blue" className='skils' icon={<><BsLaptop/><BsPhone className='icons'/></>}>
                        Responsive design
                    </Tag>
                    <Tag color="blue" className='skils' icon={<SiPhp className='icons'/>}>
                        PHP
                    </Tag>
                    <Tag color="volcano" className='skils' icon={<SiGit  className='icons'/>}>
                        Git
                    </Tag>
                    <Tag color="default" className='skils' icon={<SiGithub  className='icons'/>}>
                        GitHub
                    </Tag>
                    <Tag color="geekblue" className='skils' icon={<SiMariadb className='icons'/>}>
                        MariaDB
                    </Tag>
                    <Tag color="blue" className='skils' icon={<SiMysql className='icons'/>}>
                        MySql
                    </Tag>
                    <Tag color="green" className='skils' icon={<SiSqlite className='icons'/>}>
                        SqLite
                    </Tag>
                    <Tag color="red" className='skils' icon={<SiPostgresql className='icons'/>}>
                        PostgreSQL
                    </Tag>
                    <Tag color="blue" className='skils' icon={<SiPython className='icons'/>}>
                        Python
                    </Tag>
                    <Tag color="green" className='skils' icon={<SiDjango className='icons'/>}>
                        Django
                    </Tag>
                    <Tag color="volcano" className='skils' icon={<SiDjango className='icons'/>}>
                        Django Rest Framework
                    </Tag>
                    <Tag color="purple" className='skils' icon={<SiNginx className='icons'/>}>
                        Nginx
                    </Tag>
                    <Tag color="geekblue" className='skils' icon={<SiSelenium  className='icons'/>}>
                        Slenium
                    </Tag>
                    <Tag color="red" className='skils' icon={<SiSelenium className='icons'/>}>
                        TestNG
                    </Tag>
                    <Tag color="purple" className='skils' icon={<SiJunit5 className='icons'/>}>
                        JUnit
                    </Tag>
                    <Tag color="default" className='skils' icon={<SiGnometerminal  className='icons'/>}>
                        Linux terminal
                    </Tag>
                    <Tag color="blue" className='skils' icon={<SiWindowsterminal  className='icons'/>}>
                        Windows terminal
                    </Tag>
                    <Tag color="default" className='skils' icon={<SiLinux className='icons'/>}>
                        Linux
                    </Tag>
                    <Tag color="blue" className='skils' icon={<SiWindows className='icons'/>}>
                        Windows
                    </Tag>
                </Space>

            </Col>
        </Row>
    </Layout>
  )
}
