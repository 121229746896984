import { Button, Col, Form, Input, Layout, Progress, Row, Typography, notification } from 'antd'
import { useState } from 'react'
import { useContext, useRef, useEffect } from 'react';
import { RoutesContext } from '../../context/RoutesContext';
import { useIsVisible } from '../../assets/hooks/useIsVisible';
import emailjs from '@emailjs/browser';
import { useForm } from 'antd/es/form/Form';

type NotificationType = 'success' | 'error';

export default function Contacto() {
    const {setIndex} = useContext(RoutesContext)
    const [loading, setLoading] = useState(false)
    const ref = useRef()
    const isVisible = useIsVisible(ref);

    const [form] = useForm()

    const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type: NotificationType, text:string) => {
    api[type]({
      message: type === 'success' ? 'Correcto :)!!' : 'Upsss.. tenemos un error',
      description:
        text,
    });
  };

    useEffect(()=>{
        if(isVisible){
            setIndex(3)
        }
    },[isVisible, setIndex])

    const layout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
      };
      
      /* eslint-disable no-template-curly-in-string */
      const validateMessages = {
       
        types: {
          email: '${label} no es un correo electrónico válido!',
          name: 'Ingresa tu nombre',
          phone: 'Ingresa tu número telefónico',
          subject: 'Ingresa el motivo por el cual me contactas',
          message: 'Describe un poco lo que requieres',
        },
        
      };
      /* eslint-enable no-template-curly-in-string */
      
      const onFinish = (values: any) => {
        form.validateFields()
        setLoading(true)

        
       emailjs.send('service_djo90bd', 'template_4l5iuw5', values, '1GCmxS5swnnX0S0yJ').then(resp =>{
        if(resp.status===200 || resp.status ===201){
            openNotificationWithIcon('success', 'Se envió tu correo, te responderé cuanto antes')

            setTimeout(()=>{
             form.resetFields();
             setLoading(false)
            }, 500)
        }else{
            openNotificationWithIcon('error', 'No se envió tu correo, intentalo de nuevo o usa otra forma de contacto disponible :)')
        }
        setLoading(false)

       }).catch(error => {
        setLoading(false)
        openNotificationWithIcon('error', 'No se envió tu correo, intentalo de nuevo o usa otra forma de contacto disponible :)')

       })
      };



    return (
        <Layout className='contacto' id='contact'>
            {contextHolder}
            <Row ref={ref}>
                <Col xs={24}>
                    <Typography.Title level={1} className='title'>Contáctame</Typography.Title>
                    <Progress className='bar' percent={100} strokeColor='#51138a' showInfo={false} size={[50,12]} />
                    <Typography.Text className='text'>Puedes contactarme llenando y enviando este formulario, te responderé lo antes posible</Typography.Text>
                </Col>
            </Row>
                    <br/>
            <Row style={{flex:1, width:'100%', justifyContent:'center'}}>
                <Col xs={24} md={18} xl={16} style={{backgroundColor:'#FFF', justifyContent:'center', paddingLeft:'5%', paddingRight:'5%', paddingBottom:'40px', paddingTop:'40px'}} >
                    <Form
                        {...layout}
                        size='large'
                        name="nest-messages"
                        form={form}
                        onFinish={onFinish}
                        validateMessages={validateMessages}
                        
                    >
                        <Form.Item name={'from_name'} label="Nombre" rules={[{ required: true, message:'Ingresa tu nombre' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name={'from_email'} label="Email" rules={[{ type: 'email', required:true, message: '${label} no es un correo electrónico válido!' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name={'from_phone'} label="Teléfono" rules={[{ required:true, message:'Ingresa tu número telefónico' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name={'from_subject'} label="Asunto" rules={[{ required:true, message:'Ingresa el motivo por el cual me contactas' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name={'from_message'} label="Mensaje" rules={[{required:true, message:'Describe un poco lo que requieres'}]}>
                            <Input.TextArea />
                        </Form.Item>
                        <Form.Item style={{textAlign:'right'}}>
                            <Button type="primary" style={{width:'250px'}} htmlType="submit" size='large' loading={loading} disabled={loading}>
                                Enviar
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </Layout>
    )
}
