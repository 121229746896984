import {Button, Col, Layout, Row, Typography } from 'antd'
import { useRef, useEffect } from 'react';
import { useContext } from 'react';
import { RoutesContext } from '../../context/RoutesContext';
import { useIsVisible } from '../../assets/hooks/useIsVisible';

export default function Banner() {
    const {setIndex, routesState} = useContext(RoutesContext)
    const ref = useRef()
    const isVisible = useIsVisible(ref);

    useEffect(()=>{
        if(isVisible){
            setIndex(0)
        }
    },[isVisible, setIndex])

    const navigateTo = (index:string) =>{
        
        document.getElementById(routesState.pages[parseInt(index)])?.scrollIntoView({behavior:'smooth', inline:'center'})
           
    }

    
  return (
    <Layout className='banner ant-layout-no-padding' id='inicio' >
        <Row  className='container' ref={ref} >
            <Col xs={24} md={20} lg={24}>
                <Typography.Title level={1} className='name'>Hola, Soy Alejandro onofre (bikedevs)</Typography.Title>
            </Col>
            <Col xs={24} md={16} lg={11}>
                <Typography.Paragraph className='text'>Desarrollador FullStack, con experiencia en desarrollo de sitios y aplicaciones Web, Frontend y Backend</Typography.Paragraph>
            </Col>
            <Col xs={24}>
                <Button type='primary' danger shape='round' size='large' onClick={()=>navigateTo('2')}>PROYECTOS</Button>
            </Col>
            <a  id="scroll-btn" onClick={()=>navigateTo('1')}/>
        </Row>
    </Layout>
  )
}
