import React from 'react'
import Banner from '../components/home/Banner'
import About from '../components/home/About'
import { Layout } from 'antd'
import Projects from '../components/home/Projects'
import Contacto from '../components/home/Contacto'



export default function Home() {
  return (
    <Layout className='ant-layout-no-padding'>
        <Banner/>
        <About/>
        <Projects/>
        <Contacto/>
    </Layout>
  )
}
