import { createContext } from "react";


export interface RoutesState{
    pages:string[],
    index:number
}

export const routesInitialState:RoutesState={
    pages:['inicio', 'acerca', 'proy', 'contact'],
    index:0
}

export interface routesContextProps{
    routesState:RoutesState,
    setIndex:(index:number)=>void;
}

export const RoutesContext = createContext({} as routesContextProps);