
import { Layout } from 'antd';
import './assets/css/style.css';
import Footer from './components/Footer';
import Header from './components/Header';
import Home from './pages/Home';



function App() {

 
  

  return (
    <Layout className='ant-layout-no-padding'>
      <Header/>
      <Home/>
      <Footer/>
    </Layout>
  );
}

export default App;
