import { Button, Col, Layout, Progress, Row, Space, Tag, Typography } from 'antd'
import React from 'react'
import { AiOutlineLink } from 'react-icons/ai'
import { BiLogoPlayStore } from "react-icons/bi";
import { BsLaptop, BsPhone } from 'react-icons/bs'
import { IoIosAppstore } from "react-icons/io";
import { SiBootstrap, SiCreatereactapp, SiCss3, SiDjango, SiGit, SiGithub, SiHtml5, SiJavascript, SiMariadb, SiMysql, SiNginx, SiPhp, SiPython, SiReact, SiRedux, SiSass, SiTypescript } from 'react-icons/si'
import { useContext, useRef, useEffect } from 'react';
import { RoutesContext } from '../../context/RoutesContext'
import { useIsVisible } from '../../assets/hooks/useIsVisible'

export default function Projects() {
    const {setIndex} = useContext(RoutesContext)
    const ref = useRef()
    const isVisible = useIsVisible(ref);

    useEffect(()=>{
        if(isVisible){
            setIndex(2)
        }
    },[isVisible, setIndex])


  return (
    <Layout className='projects' id='proy' >
        <Row >
            <Col xs={24}>
                <Typography.Title level={1} className='title'>Proyectos</Typography.Title>
                <Progress className='bar' percent={100} strokeColor='#51138a' showInfo={false} size={[50,12]} />
                <Typography.Text className='text'>A continuación, te muestro algunos proyectos que he realizado y algunos en los que he participado</Typography.Text>
            </Col>
        </Row>
        <Row gutter={[48, 60]} className='projects_list' ref={ref}>
            <Col xs={24} lg={12}>
                <img src={require('./../../assets/images/projects/fracs.png')} alt='project_img'/>
            </Col>
            <Col xs={24} lg={12}>
                <Typography.Title level={2} className='subTitle'>App móvil Fracs</Typography.Title>
                <Typography.Paragraph className='text'>Fracs es una aplicación enfocada a los bienes raíces, orientándose principalmente en la promoción de desarrollos 
                inmobiliarios, mostrando las amenidades y propiedades con las que cuentan, así como su ubicación, la compra-venta no se encuentra integrada dentro 
                de la App, debido al tipo de producto, por lo que los clientes deben agendar una cita a través de los medios de contacto especificados en la App.</Typography.Paragraph>
                <Typography.Title level={2} >Tecnologías usadas</Typography.Title>
                <Space size={[0, 16]} wrap>
                    <Tag color="cyan" className='skils' icon={<SiRedux className='icons'/>}>
                        Redux
                    </Tag>
                    <Tag color="blue" className='skils' icon={<SiCreatereactapp className='icons'/>}>
                        React Native
                    </Tag>
                    <Tag color="blue" className='skils' icon={<SiTypescript className='icons'/>}>
                        TypeScript
                    </Tag>
                    <Tag color="pink" className='skils' icon={<SiSass className='icons'/>}>
                        SASS
                    </Tag>
                    <Tag color="blue" className='skils' icon={<><BsLaptop/><BsPhone className='icons'/></>}>
                        Responsive design
                    </Tag>
                    <Tag color="volcano" className='skils' icon={<SiGit  className='icons'/>}>
                        Git
                    </Tag>
                    <Tag color="default" className='skils' icon={<SiGithub  className='icons'/>}>
                        GitHub
                    </Tag>
                    <Tag color="geekblue" className='skils' icon={<SiMariadb className='icons'/>}>
                        MariaDB
                    </Tag>
                </Space>
                <Typography.Title level={2}>Ver en línea</Typography.Title>
                <Button size='large' type='primary' href='https://apps.apple.com/mx/app/fracs/id6462194894' style={{marginRight:2, background:'#148df7'}} target='_blank' icon={<IoIosAppstore/>}>AppStore</Button>
                <Button size='large' type='primary' href='https://play.google.com/store/apps/details?id=com.bloque9.fracc' style={{marginLeft:2, background:'#01875f'}} target='_blank' icon={<BiLogoPlayStore />}>PlayStore</Button>
            </Col>
            <Col xs={24} lg={12}>
                <img src={require('./../../assets/images/projects/rutamexico.png')} alt='project_img'/>
            </Col>
            <Col xs={24} lg={12}>
                <Typography.Title level={2} className='subTitle'>Ruta México</Typography.Title>
                <Typography.Paragraph className='text'>Ruta México es una empresa dedicada a la organización de viajes, principalmente grupales y 
                académicos dentro de México y algunos países del extranjero, incluyendo actividades especiales que enriquecerán la experiencia de 
                los grupos que viajan.</Typography.Paragraph>
                <Typography.Title level={2} >Tecnologías usadas</Typography.Title>
                <Space size={[0, 16]} wrap>
                    
                    <Tag color="gold" className='skils' icon={<SiJavascript className='icons'/>}>
                        JavaScript
                    </Tag>
                    <Tag color="pink" className='skils' icon={<SiSass className='icons'/>}>
                        SASS
                    </Tag>
                    <Tag color="orange" className='skils' icon={<SiHtml5 className='icons'/>}>
                        HTML
                    </Tag>
                    <Tag color="blue" className='skils' icon={<SiCss3 className='icons'/>}>
                        CSS
                    </Tag>
                    <Tag color="purple" className='skils' icon={<SiBootstrap className='icons'/>}>
                        Bootstrap
                    </Tag>
                    <Tag color="blue" className='skils' icon={<><BsLaptop/><BsPhone className='icons'/></>}>
                        Responsive design
                    </Tag>
                    <Tag color="blue" className='skils' icon={<SiPhp className='icons'/>}>
                        PHP
                    </Tag>
                    <Tag color="volcano" className='skils' icon={<SiGit  className='icons'/>}>
                        Git
                    </Tag>
                    <Tag color="default" className='skils' icon={<SiGithub  className='icons'/>}>
                        GitHub
                    </Tag>
                </Space>
                <Typography.Title level={2}>Ver en línea</Typography.Title>
                <Button size='large' type='primary' href='https://www.rutamexico.com.mx/' target='_blank' icon={<AiOutlineLink/>}>Ver en línea</Button>
            </Col>
            <Col xs={24} lg={12}>
                <img src={require('./../../assets/images/projects/tmc.png')} alt='project_img'/>
            </Col>
            <Col xs={24} lg={12}>
                <Typography.Title level={2} className='subTitle'>TMC</Typography.Title>
                <Typography.Paragraph className='text'>TMC es una compañía dedicada al transporte de mercancías de diferente tipo y especializada en el traslado de
                 materiales peligrosos, cuenta con operadores certificados y con experiencia, cumpliendo las normas de seguridad y calidad establecidas.</Typography.Paragraph>
                <Typography.Title level={2} >Tecnologías usadas</Typography.Title>
                <Space size={[0, 16]} wrap>
                    
                    <Tag color="gold" className='skils' icon={<SiJavascript className='icons'/>}>
                        JavaScript
                    </Tag>
                    <Tag color="pink" className='skils' icon={<SiSass className='icons'/>}>
                        SASS
                    </Tag>
                    <Tag color="orange" className='skils' icon={<SiHtml5 className='icons'/>}>
                        HTML
                    </Tag>
                    <Tag color="blue" className='skils' icon={<SiCss3 className='icons'/>}>
                        CSS
                    </Tag>
                    <Tag color="purple" className='skils' icon={<SiBootstrap className='icons'/>}>
                        Bootstrap
                    </Tag>
                    <Tag color="blue" className='skils' icon={<><BsLaptop/><BsPhone className='icons'/></>}>
                        Responsive design
                    </Tag>
                    <Tag color="blue" className='skils' icon={<SiPhp className='icons'/>}>
                        PHP
                    </Tag>
                    <Tag color="volcano" className='skils' icon={<SiGit  className='icons'/>}>
                        Git
                    </Tag>
                    <Tag color="default" className='skils' icon={<SiGithub  className='icons'/>}>
                        GitHub
                    </Tag>
                </Space>
                <Typography.Title level={2}>Ver en línea</Typography.Title>
                <Button size='large' type='primary'  href='https://tmc.com.mx/' target='_blank' icon={<AiOutlineLink/>}>Ver en línea</Button>
            </Col>
            <Col xs={24} lg={12}>
                <img src={require('./../../assets/images/projects/tendencia.png')} alt='project_img'/>
            </Col>
            <Col xs={24} lg={12}>
                <Typography.Title level={2} className='subTitle'>Tendencia IM</Typography.Title>
                <Typography.Paragraph className='text'>Agencia mexicana de investigación de mercados que ofrece distintos tipos de soluciones, de vanguardia tecnológica y 
                un compromiso integral en cada uno de los estudios que realiza.</Typography.Paragraph>
                <Typography.Title level={2} >Tecnologías usadas</Typography.Title>
                <Space size={[0, 16]} wrap>
                    
                    <Tag color="gold" className='skils' icon={<SiJavascript className='icons'/>}>
                        JavaScript
                    </Tag>
                    <Tag color="pink" className='skils' icon={<SiSass className='icons'/>}>
                        SASS
                    </Tag>
                    <Tag color="orange" className='skils' icon={<SiHtml5 className='icons'/>}>
                        HTML
                    </Tag>
                    <Tag color="blue" className='skils' icon={<SiCss3 className='icons'/>}>
                        CSS
                    </Tag>
                    <Tag color="purple" className='skils' icon={<SiBootstrap className='icons'/>}>
                        Bootstrap
                    </Tag>
                    <Tag color="blue" className='skils' icon={<><BsLaptop/><BsPhone className='icons'/></>}>
                        Responsive design
                    </Tag>
                    <Tag color="blue" className='skils' icon={<SiPhp className='icons'/>}>
                        PHP
                    </Tag>
                    <Tag color="volcano" className='skils' icon={<SiGit  className='icons'/>}>
                        Git
                    </Tag>
                    <Tag color="default" className='skils' icon={<SiGithub  className='icons'/>}>
                        GitHub
                    </Tag>
                </Space>
                <Typography.Title level={2}>Ver en línea</Typography.Title>
                <Button size='large' type='primary' href='https://www.tendenciaim.com/' target='_blank' icon={<AiOutlineLink/>}>Ver en línea</Button>
            </Col>
            <Col xs={24} lg={12}>
                <img src={require('./../../assets/images/projects/hilosyrafias.png')} alt='project_img'/>
            </Col>
            <Col xs={24} lg={12}>
                <Typography.Title level={2} className='subTitle'>San José - Hilos y rafias</Typography.Title>
                <Typography.Paragraph className='text'>Empresa dedicada a la fabricación de arpillas en medidas especiales sobre pedido. Cuanta con arpilla fabricada con 
                monofilamento y rafia, combinación perfecta para obtener resistencia para almacenar y transportar distintos tipos de productos.</Typography.Paragraph>
                <Typography.Title level={2} >Tecnologías usadas</Typography.Title>
                <Space size={[0, 16]} wrap>
                    
                    <Tag color="gold" className='skils' icon={<SiJavascript className='icons'/>}>
                        JavaScript
                    </Tag>
                    <Tag color="pink" className='skils' icon={<SiSass className='icons'/>}>
                        SASS
                    </Tag>
                    <Tag color="orange" className='skils' icon={<SiHtml5 className='icons'/>}>
                        HTML
                    </Tag>
                    <Tag color="blue" className='skils' icon={<SiCss3 className='icons'/>}>
                        CSS
                    </Tag>
                    <Tag color="purple" className='skils' icon={<SiBootstrap className='icons'/>}>
                        Bootstrap
                    </Tag>
                    <Tag color="blue" className='skils' icon={<><BsLaptop/><BsPhone className='icons'/></>}>
                        Responsive design
                    </Tag>
                    <Tag color="blue" className='skils' icon={<SiPhp className='icons'/>}>
                        PHP
                    </Tag>
                    <Tag color="volcano" className='skils' icon={<SiGit  className='icons'/>}>
                        Git
                    </Tag>
                    <Tag color="default" className='skils' icon={<SiGithub  className='icons'/>}>
                        GitHub
                    </Tag>
                </Space>
                <Typography.Title level={2}>Ver en línea</Typography.Title>
                <Button size='large' type='primary' href='https://hilosyrafias.com/' target='_blank' icon={<AiOutlineLink/>}>Ver en línea</Button>
            </Col>
            <Col xs={24} lg={12}>
                <img src={require('./../../assets/images/projects/fedece.png')} alt='project_img'/>
            </Col>
            <Col xs={24} lg={12}>
                <Typography.Title level={2} className='subTitle'>Fedece</Typography.Title>
                <Typography.Paragraph className='text'>Compañía dedicada a la venta de materiales agrícolas, 
                proporcionando al sector agropecuario la mayor diversificación de productos para el cuidado de la agricultura.</Typography.Paragraph>
                <Typography.Title level={2} >Tecnologías usadas</Typography.Title>
                <Space size={[0, 16]} wrap>
                    
                    <Tag color="gold" className='skils' icon={<SiJavascript className='icons'/>}>
                        JavaScript
                    </Tag>
                    <Tag color="pink" className='skils' icon={<SiSass className='icons'/>}>
                        SASS
                    </Tag>
                    <Tag color="orange" className='skils' icon={<SiHtml5 className='icons'/>}>
                        HTML
                    </Tag>
                    <Tag color="blue" className='skils' icon={<SiCss3 className='icons'/>}>
                        CSS
                    </Tag>
                    <Tag color="purple" className='skils' icon={<SiBootstrap className='icons'/>}>
                        Bootstrap
                    </Tag>
                    <Tag color="blue" className='skils' icon={<><BsLaptop/><BsPhone className='icons'/></>}>
                        Responsive design
                    </Tag>
                    <Tag color="blue" className='skils' icon={<SiPhp className='icons'/>}>
                        PHP
                    </Tag>
                    <Tag color="volcano" className='skils' icon={<SiGit  className='icons'/>}>
                        Git
                    </Tag>
                    <Tag color="default" className='skils' icon={<SiGithub  className='icons'/>}>
                        GitHub
                    </Tag>
                </Space>
                <Typography.Title level={2}>Ver en línea</Typography.Title>
                <Button size='large' type='primary' href='https://fedece.mx/' target='_blank' icon={<AiOutlineLink/>}>Ver en línea</Button>
            </Col>
            <Col xs={24} lg={12}>
                <img src={require('./../../assets/images/projects/eifraxo.png')} alt='project_img'/>
            </Col>
            <Col xs={24} lg={12}>
                <Typography.Title level={2} className='subTitle'>FRAXO</Typography.Title>
                <Typography.Paragraph className='text'>Empresa dedicada a la comercialización al menudeo y mayoreo de Artículos y Equipo de Cocina, ya sea para equipar un hogar, 
                restaurante, hospital y proyectos especiales a la medida; a fin de otorgar todas las herramientas para la productividad y eficiencia de su negocio.</Typography.Paragraph>
                <Typography.Paragraph className='text'>En este proyecto colaboré codificando la parte Frontend (interfaz).</Typography.Paragraph>
                <Typography.Title level={2} >Tecnologías usadas</Typography.Title>
                <Space size={[0, 16]} wrap>
                    
                    <Tag color="blue" className='skils' icon={<SiPython className='icons'/>}>
                        Python
                    </Tag>
                    <Tag color="green" className='skils' icon={<SiDjango className='icons'/>}>
                        Django
                    </Tag>
                    <Tag color="gold" className='skils' icon={<SiJavascript className='icons'/>}>
                        JavaScript
                    </Tag>
                    <Tag color="pink" className='skils' icon={<SiSass className='icons'/>}>
                        SASS
                    </Tag>
                    <Tag color="orange" className='skils' icon={<SiHtml5 className='icons'/>}>
                        HTML
                    </Tag>
                    <Tag color="blue" className='skils' icon={<SiCss3 className='icons'/>}>
                        CSS
                    </Tag>
                    <Tag color="purple" className='skils' icon={<SiBootstrap className='icons'/>}>
                        Bootstrap
                    </Tag>
                    <Tag color="blue" className='skils' icon={<><BsLaptop/><BsPhone className='icons'/></>}>
                        Responsive design
                    </Tag>
                    <Tag color="volcano" className='skils' icon={<SiGit  className='icons'/>}>
                        Git
                    </Tag>
                    <Tag color="default" className='skils' icon={<SiGithub  className='icons'/>}>
                        GitHub
                    </Tag>
                    <Tag color="blue" className='skils' icon={<SiMysql className='icons'/>}>
                        MySql
                    </Tag>
                </Space>
                <Typography.Title level={2}>Ver en línea</Typography.Title>
                <Button size='large' type='primary' href='https://escowill.com/' target='_blank' icon={<AiOutlineLink/>}>Ver en línea</Button>
            </Col>
            <Col xs={24} lg={12}>
                <img src={require('./../../assets/images/projects/escowill.png')} alt='project_img'/>
            </Col>
            <Col xs={24} lg={12}>
                <Typography.Title level={2} className='subTitle'>Escowill</Typography.Title>
                <Typography.Paragraph className='text'>Fabricante de envases, botellas de PET y tapas de diferentes tamaños y formas, ofrece sus productos a
                 diferentes compañías incluyendo el mercado de salud y belleza.</Typography.Paragraph>
                <Typography.Paragraph className='text'>En este proyecto colaboré codificando la parte Frontend (interfaz).</Typography.Paragraph>
                <Typography.Title level={2} >Tecnologías usadas</Typography.Title>
                <Space size={[0, 16]} wrap>
                    
                    <Tag color="blue" className='skils' icon={<SiPython className='icons'/>}>
                        Python
                    </Tag>
                    <Tag color="green" className='skils' icon={<SiDjango className='icons'/>}>
                        Django
                    </Tag>
                    <Tag color="gold" className='skils' icon={<SiJavascript className='icons'/>}>
                        JavaScript
                    </Tag>
                    <Tag color="pink" className='skils' icon={<SiSass className='icons'/>}>
                        SASS
                    </Tag>
                    <Tag color="orange" className='skils' icon={<SiHtml5 className='icons'/>}>
                        HTML
                    </Tag>
                    <Tag color="blue" className='skils' icon={<SiCss3 className='icons'/>}>
                        CSS
                    </Tag>
                    <Tag color="purple" className='skils' icon={<SiBootstrap className='icons'/>}>
                        Bootstrap
                    </Tag>
                    <Tag color="blue" className='skils' icon={<><BsLaptop/><BsPhone className='icons'/></>}>
                        Responsive design
                    </Tag>
                    <Tag color="volcano" className='skils' icon={<SiGit  className='icons'/>}>
                        Git
                    </Tag>
                    <Tag color="default" className='skils' icon={<SiGithub  className='icons'/>}>
                        GitHub
                    </Tag>
                    <Tag color="blue" className='skils' icon={<SiMysql className='icons'/>}>
                        MySql
                    </Tag>
                </Space>
                <Typography.Title level={2}>Ver en línea</Typography.Title>
                <Button size='large' type='primary' href='https://escowill.com/' target='_blank' icon={<AiOutlineLink/>}>Ver en línea</Button>
            </Col>
            <Col xs={24} lg={12}>
                <img src={require('./../../assets/images/projects/taisa.png')} alt='project_img'/>
            </Col>
            <Col xs={24} lg={12}>
                <Typography.Title level={2} className='subTitle'>TAISA</Typography.Title>
                <Typography.Paragraph className='text'>Empresa dedicada a mejorar la productividad y rentabilidad de las empresas, cuenta con experiencia en el desarrollo de sistemas 
                y aplicaciones para la automatización industrial, es una empresa integradora y certificada por General Electric.</Typography.Paragraph>
                <Typography.Paragraph className='text'>La participación que tuve en este proyecto fue codificando el Frontend de la sección productos (interfaz) y 
                consumiendo los recursos (API's) necesarias para el despliegue de la información requerida.</Typography.Paragraph>
                <Typography.Title level={2} >Tecnologías usadas</Typography.Title>
                <Space size={[0, 16]} wrap>
                    
                    <Tag color="blue" className='skils' icon={<SiPython className='icons'/>}>
                        Python
                    </Tag>
                    <Tag color="green" className='skils' icon={<SiDjango className='icons'/>}>
                        Django
                    </Tag>
                    <Tag color="gold" className='skils' icon={<SiJavascript className='icons'/>}>
                        JavaScript
                    </Tag>
                    <Tag color="pink" className='skils' icon={<SiSass className='icons'/>}>
                        SASS
                    </Tag>
                    <Tag color="orange" className='skils' icon={<SiHtml5 className='icons'/>}>
                        HTML
                    </Tag>
                    <Tag color="blue" className='skils' icon={<SiCss3 className='icons'/>}>
                        CSS
                    </Tag>
                    <Tag color="purple" className='skils' icon={<SiBootstrap className='icons'/>}>
                        Bootstrap
                    </Tag>
                    <Tag color="blue" className='skils' icon={<><BsLaptop/><BsPhone className='icons'/></>}>
                        Responsive design
                    </Tag>
                    <Tag color="volcano" className='skils' icon={<SiGit  className='icons'/>}>
                        Git
                    </Tag>
                    <Tag color="default" className='skils' icon={<SiGithub  className='icons'/>}>
                        GitHub
                    </Tag>
                    <Tag color="blue" className='skils' icon={<SiMysql className='icons'/>}>
                        MySql
                    </Tag>
                </Space>
                <Typography.Title level={2}>Ver en línea</Typography.Title>
                <Button size='large' type='primary' href='https://productos.taisa-mx.com/catalogo/' target='_blank' icon={<AiOutlineLink/>}>Ver en línea</Button>
            </Col>
            <Col xs={24} lg={12}>
                <img src={require('./../../assets/images/projects/helo.png')} alt='project_img'/>
            </Col>
            <Col xs={24} lg={12}>
                <Typography.Title level={2} className='subTitle'>Helo.tv</Typography.Title>
                <Typography.Paragraph className='text'>Empresa dedicada al cine, con un gran catálogo de proyectos y directores que han participado en 
                diferentes documentales, series y películas reconocidas.</Typography.Paragraph>
                <Typography.Paragraph className='text'>Colaboré codificando el Frontend de la primera versión.</Typography.Paragraph>
                <Typography.Title level={2} >Tecnologías usadas</Typography.Title>
                <Space size={[0, 16]} wrap>
                    
                    <Tag color="gold" className='skils' icon={<SiJavascript className='icons'/>}>
                        JavaScript
                    </Tag>
                    <Tag color="pink" className='skils' icon={<SiSass className='icons'/>}>
                        SASS
                    </Tag>
                    <Tag color="orange" className='skils' icon={<SiHtml5 className='icons'/>}>
                        HTML
                    </Tag>
                    <Tag color="blue" className='skils' icon={<SiCss3 className='icons'/>}>
                        CSS
                    </Tag>
                    <Tag color="purple" className='skils' icon={<SiBootstrap className='icons'/>}>
                        Bootstrap
                    </Tag>
                    <Tag color="blue" className='skils' icon={<><BsLaptop/><BsPhone className='icons'/></>}>
                        Responsive design
                    </Tag>
                    <Tag color="blue" className='skils' icon={<SiPhp className='icons'/>}>
                        PHP
                    </Tag>
                    <Tag color="volcano" className='skils' icon={<SiGit  className='icons'/>}>
                        Git
                    </Tag>
                    <Tag color="default" className='skils' icon={<SiGithub  className='icons'/>}>
                        GitHub
                    </Tag>
                </Space>
                <Typography.Title level={2}>Ver en línea</Typography.Title>
                <Button size='large' type='primary' href='https://helo.tv/' target='_blank' icon={<AiOutlineLink/>}>Ver en línea</Button>
            </Col>
            <Col xs={24} lg={12}>
                <img src={require('./../../assets/images/projects/cobranza.png')} alt='project_img'/>
            </Col>
            <Col xs={24} lg={12}>
                <Typography.Title level={2} className='subTitle'>Sistema de cobranza</Typography.Title>
                <Typography.Paragraph className='text'>Es un sistema de cobranza orientado a la generación y envío de cobros automáticos a los distintos 
                clientes registrados en el sistema, cuenta con reportes esenciales que muestran adeudos y pagos realizados.</Typography.Paragraph>
                <Typography.Title level={2} >Tecnologías usadas</Typography.Title>
                <Space size={[0, 16]} wrap>
                    
                    <Tag color="blue" className='skils' icon={<SiPython className='icons'/>}>
                        Python
                    </Tag>
                    <Tag color="green" className='skils' icon={<SiDjango className='icons'/>}>
                        Django
                    </Tag>
                    <Tag color="volcano" className='skils' icon={<SiDjango className='icons'/>}>
                        Django Rest Framework
                    </Tag>
                    <Tag color="gold" className='skils' icon={<SiJavascript className='icons'/>}>
                        JavaScript
                    </Tag>
                    <Tag color="orange" className='skils' icon={<SiHtml5 className='icons'/>}>
                        HTML
                    </Tag>
                    <Tag color="blue" className='skils' icon={<SiCss3 className='icons'/>}>
                        CSS
                    </Tag>
                    <Tag color="purple" className='skils' icon={<SiBootstrap className='icons'/>}>
                        Bootstrap
                    </Tag>
                    <Tag color="blue" className='skils' icon={<><BsLaptop/><BsPhone className='icons'/></>}>
                        Responsive design
                    </Tag>
                    <Tag color="volcano" className='skils' icon={<SiGit  className='icons'/>}>
                        Git
                    </Tag>
                    <Tag color="default" className='skils' icon={<SiGithub  className='icons'/>}>
                        GitHub
                    </Tag>
                    <Tag color="blue" className='skils' icon={<SiMysql className='icons'/>}>
                        MySql
                    </Tag>
                    <Tag color="purple" className='skils' icon={<SiNginx className='icons'/>}>
                        Nginx
                    </Tag>
                    
                </Space>
                <Typography.Title level={2}>Ver en línea</Typography.Title>
                <Button size='large' type='primary' href='https://cargo.coti.dev/' target='_blank' icon={<AiOutlineLink/>}>Ver en línea</Button>
            </Col>
            <Col xs={24} lg={12}>
                <img src={require('./../../assets/images/projects/rikitsimo.png')} alt='project_img'/>
            </Col>
            <Col xs={24} lg={12}>
                <Typography.Title level={2} className='subTitle'>Rikitsimo (proyecto cancelado)</Typography.Title>
                <Typography.Paragraph className='text'>Rikitsimo pretendía ser una aplicación web enfocada a la venta de comida dentro de una zona local, donde se podrían registrar 
                comercios y negocios de comida y recibir pedidos a través de WhatsApp.</Typography.Paragraph>
                <Typography.Title level={2} >Tecnologías usadas</Typography.Title>
                <Space size={[0, 16]} wrap>
                    
                    <Tag color="geekblue" className='skils' icon={<SiReact className='icons'/>}>
                        React js
                    </Tag>
                    <Tag color="cyan" className='skils' icon={<SiRedux className='icons'/>}>
                        Redux
                    </Tag>
                    <Tag color="blue" className='skils' icon={<SiTypescript className='icons'/>}>
                        TypeScript
                    </Tag>
                    <Tag color="gold" className='skils' icon={<SiJavascript className='icons'/>}>
                        JavaScript
                    </Tag>
                    <Tag color="pink" className='skils' icon={<SiSass className='icons'/>}>
                        SASS
                    </Tag>
                    <Tag color="orange" className='skils' icon={<SiHtml5 className='icons'/>}>
                        HTML
                    </Tag>
                    <Tag color="blue" className='skils' icon={<SiCss3 className='icons'/>}>
                        CSS
                    </Tag>
                    <Tag color="purple" className='skils' icon={<SiBootstrap className='icons'/>}>
                        Bootstrap
                    </Tag>
                    <Tag color="blue" className='skils' icon={<><BsLaptop/><BsPhone className='icons'/></>}>
                        Responsive design
                    </Tag>
                    <Tag color="volcano" className='skils' icon={<SiGit  className='icons'/>}>
                        Git
                    </Tag>
                    <Tag color="default" className='skils' icon={<SiGithub  className='icons'/>}>
                        GitHub
                    </Tag>
                    <Tag color="geekblue" className='skils' icon={<SiMariadb className='icons'/>}>
                        MariaDB
                    </Tag>
                    <Tag color="blue" className='skils' icon={<SiPython className='icons'/>}>
                        Python
                    </Tag>
                    <Tag color="green" className='skils' icon={<SiDjango className='icons'/>}>
                        Django
                    </Tag>
                    <Tag color="volcano" className='skils' icon={<SiDjango className='icons'/>}>
                        Django Rest Framework
                    </Tag>
                    <Tag color="purple" className='skils' icon={<SiNginx className='icons'/>}>
                        Nginx
                    </Tag>
                </Space>
                <Typography.Title level={2}>Ver en línea</Typography.Title>
                <Button size='large' type='primary' href='https://rikitsimo.com/' target='_blank' icon={<AiOutlineLink/>}>Ver en línea</Button>
            </Col>
            <Col xs={24} lg={12}>
                <img src={require('./../../assets/images/projects/olamalu.png')} alt='project_img'/>
            </Col>
            <Col xs={24} lg={12}>
                <Typography.Title level={2} className='subTitle'>Ola Malú (Proyecto pausado)</Typography.Title>
                <Typography.Paragraph className='text'>Ola Malú es un proyecto e-commerce, surge como una forma de retomar la idea de Rikitsimo, pero enfocado 
                solo a un negocio, con opciones de envío a domicilio (validando la cobertura) o recoger en tienda.</Typography.Paragraph>
                <Typography.Title level={2} >Tecnologías usadas</Typography.Title>
                <Space size={[0, 16]} wrap>
                    
                <Tag color="geekblue" className='skils' icon={<SiReact className='icons'/>}>
                        React js
                    </Tag>
                    <Tag color="cyan" className='skils' icon={<SiRedux className='icons'/>}>
                        Redux
                    </Tag>
                    <Tag color="blue" className='skils' icon={<SiTypescript className='icons'/>}>
                        TypeScript
                    </Tag>
                    <Tag color="gold" className='skils' icon={<SiJavascript className='icons'/>}>
                        JavaScript
                    </Tag>
                    <Tag color="pink" className='skils' icon={<SiSass className='icons'/>}>
                        SASS
                    </Tag>
                    <Tag color="orange" className='skils' icon={<SiHtml5 className='icons'/>}>
                        HTML
                    </Tag>
                    <Tag color="blue" className='skils' icon={<SiCss3 className='icons'/>}>
                        CSS
                    </Tag>
                    <Tag color="purple" className='skils' icon={<SiBootstrap className='icons'/>}>
                        Bootstrap
                    </Tag>
                    <Tag color="blue" className='skils' icon={<><BsLaptop/><BsPhone className='icons'/></>}>
                        Responsive design
                    </Tag>
                    <Tag color="volcano" className='skils' icon={<SiGit  className='icons'/>}>
                        Git
                    </Tag>
                    <Tag color="default" className='skils' icon={<SiGithub  className='icons'/>}>
                        GitHub
                    </Tag>
                    <Tag color="geekblue" className='skils' icon={<SiMariadb className='icons'/>}>
                        MariaDB
                    </Tag>
                    <Tag color="blue" className='skils' icon={<SiPython className='icons'/>}>
                        Python
                    </Tag>
                    <Tag color="green" className='skils' icon={<SiDjango className='icons'/>}>
                        Django
                    </Tag>
                    <Tag color="volcano" className='skils' icon={<SiDjango className='icons'/>}>
                        Django Rest Framework
                    </Tag>
                    <Tag color="purple" className='skils' icon={<SiNginx className='icons'/>}>
                        Nginx
                    </Tag>
                </Space>
                <Typography.Title level={2}>Ver en línea</Typography.Title>
                <Button size='large' type='primary' href='https://olamalu.rikitsimo.com/' target='_blank' icon={<AiOutlineLink/>}>Ver en línea</Button>
            </Col>
        </Row>
    </Layout>
  )
}
