import { RoutesState } from "./RoutesContext"

type RoutesAction = |{type:'setIndex', payload:number}

export const RoutesReducer = (state:RoutesState, action: RoutesAction) => {
    switch(action.type){
        case 'setIndex':
            return {
                ...state,
                index: action.payload
            }
        default:
            return {
                ...state
            }
    }
}

