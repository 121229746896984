import { Col, Layout, List, Row, Tooltip, Typography } from 'antd'
import { AiFillLinkedin, AiFillFacebook, AiTwotoneMail, AiFillGithub } from "react-icons/ai";
import React from 'react'

export default function Footer() {
  return (
    <Layout className='ant-layout-no-padding'>
      <Row className='footer '>
        <Col xs={24} sm={24} md={24} lg={6} className='logo_footer'>
          <img src={require('./../assets/images/logo_.png')} width={150} />
          <Typography.Title level={2} style={{fontWeight: 'bold'}}>BikeDevs</Typography.Title>
        </Col>
        <Col xs={24} sm={24} md={24} lg={9}  className='info'>
          <Typography.Title level={2} style={{fontWeight:'bolder', textTransform:'uppercase'}} className='withe_text'>Alejandro Onofre</Typography.Title>
          <Typography.Paragraph  className='withe_text'>Desarrolador FullStack con experiencia en Frontend y Backend</Typography.Paragraph>
          <Typography.Title level={3} className='withe_text'>¿Necesitas una página para tu negocio?</Typography.Title>
          <Typography.Paragraph  className='withe_text'>Puedes contactarme y con gusto te daré una propuesta y cotización</Typography.Paragraph>
        </Col>
        <Col xs={24} sm={24} md={24} lg={9}  className='social' style={{justifyContent:'center', textAlign:'center', alignItems:'center'}}>
        <Typography.Title level={3} className='withe_text'>Puedes encontrarme en:</Typography.Title>
          <ul className='footer_icons' style={{padding:0}}>
            <li><Tooltip title="Visita mi perfil en LinkedIn"><AiFillLinkedin onClick={()=>{window.open('https://www.linkedin.com/in/alejandro-onofre-cornejo-59095b145/', '_blank')}} size={30}/></Tooltip></li>
            <li><Tooltip title="Contáctame vía correo electrónico"><AiTwotoneMail onClick={()=>{window.location.assign('mailto:contacto@bikedevs.mx')}} size={30}/></Tooltip></li>
            <li><Tooltip title="Mi GitHub"><AiFillGithub onClick={()=>{window.open('https://github.com/bikeDevs/', '_blank')}} size={30}/></Tooltip></li>
            <li><Tooltip title="Mi Facebook"><AiFillFacebook onClick={()=>{window.open('https://www.facebook.com/BikeDevs/', '_blank')}} size={30}/></Tooltip></li>
            <li><Tooltip title="Mi Facebook personal"><AiFillFacebook onClick={()=>{window.open('https://www.facebook.com/alejandro.onofre.92/', '_blank')}} size={30}/></Tooltip></li>
          </ul>
        </Col>
      </Row>
    </Layout>
  )
}
