import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ConfigProvider } from 'antd';
import { RoutesProvider } from './context/RoutesProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RoutesProvider>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#51138a',
          },
        }}
      >
        <App />
      </ConfigProvider>

    </RoutesProvider>
  </React.StrictMode>
);
