import React, { useEffect, useState } from 'react'
import { Menu, Layout, Drawer, Button } from 'antd'
import type {MenuProps} from 'antd'
import { useContext } from 'react';
import { RoutesContext } from '../context/RoutesContext';
import { AiOutlineMenu } from 'react-icons/ai';

const items:MenuProps|any['items'] = [

    {
        icon:<img src={require('./../assets/images/logo_.png')} height={40} />,
        label: 'BikeDevs',
        key:'0',
        className:'logo',
        style:{display: 'flex',alignItems:'center', justifyContent:'center', fontSize:20, fontWeight:'bold'}
    },
    {type:'divider', style:{flex:1, border:0, padding:0, margin:0}},
    
    {
        label:'Inicio',
        key:'0',
        style:{paddingLeft:10, paddingRight:10},

    },
    {
        label:'Acerca de mi',
        key:'1',
        style:{paddingLeft:10, paddingRight:10},
        
    },
    {
        label:'Proyectos',
        key:'2',
        style:{paddingLeft:10, paddingRight:10},


    },
    {
        label:'Contáctame',
        key:'3',
        style:{paddingLeft:10, paddingRight:10},

    },
]

export default function Header() {
    const {routesState} = useContext(RoutesContext)
    const [active, setActive] =React.useState('0')

    const [openDrawer, setOpenDrawer]=useState(false)

    useEffect(()=>{
        setActive(routesState.index+'')
    },[routesState])

    const navigateTo = (index:string) =>{
        setActive(index)
        
        document.getElementById(routesState.pages[parseInt(index)])?.scrollIntoView({behavior:'smooth', inline:'center'})
           
    }
  return (
    <>
        <Menu className='menuDesktop ' items={items} onClick={(e)=> navigateTo(e.key)} selectedKeys={[active]} mode='horizontal' activeKey={active}/>

        <div className='menuMovile '>
            <div className='logo' style={{display:'flex',justifyContent:'center', alignItems:'center'}}>
                <a style={{display:'flex',justifyContent:'center', alignItems:'center'}} onClick={()=>navigateTo('0')}>
                    {items[0].icon}
                    <span className='ant-menu-title-content'>{items[0].label}</span>
                </a>
                <div style={{flex:1}}></div>
                <AiOutlineMenu size={30} onClick={()=>setOpenDrawer(true)} color='#51138a' style={{marginRight:'4%'}}/>
            </div>
            <Drawer
                open={openDrawer}
                onClose={()=>setOpenDrawer(false)}
            >
                {items?.map(item =>{

                    if(item.key && !item.icon){
                        return <a type='text' className={(active===item?.key) ? 'itemDrawer active' :'itemDrawer' } onClick={()=>[navigateTo(item.key+''), setOpenDrawer(false)]}>{item.label}</a>
                    }
            } )}
            </Drawer>
        </div>
    </>
  )
}


const logoStyle:React.CSSProperties = {
    
}