import {useReducer} from 'react'
import { RoutesReducer } from './RoutesReducer'
import { routesInitialState, RoutesContext } from './RoutesContext';

export const RoutesProvider = ({children}: {children:JSX.Element}) => {
    const [routesState, dispatch] = useReducer(RoutesReducer, routesInitialState);

    const setIndex = (index:number) =>{
        dispatch({type: 'setIndex', payload:index});
    }

    return (
        <RoutesContext.Provider value={{
            routesState,
            setIndex
        }}>
            {children}
        </RoutesContext.Provider>
    )

}
